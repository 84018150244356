<template>
  <v-autocomplete
    v-model="selectedProduct"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="products"
    no-filter
    item-value="id"
    clearable
    return-object
    :search-input.sync="search"
    :placeholder="this.placeholder ? this.placeholder : 'Search with at least 3 characters'"
    :label="this.label ? this.label : 'Product'"
    :multiple="multiple"
    :loading="loading"
    :rules="[validate()]"
    chips
    hide-no-data
  >
    <template v-slot:selection="{ item }">
      <v-chip text-color="white" small v-if="item.productKey">
        {{ displayItem(item) }}
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <template v-if="item.productKey">
        {{ displayItem(item) }}
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/gapp-components/services/api.service";

export default {
  name: "ProductsField",
  props: {
    value: [Array, Object],
    minimumCount: {
      type: Number,
      default: 0
    },
    maximumCount: {
      type: Number,
      default: 0
    },
    programGroupKey: {
      type: String,
      required: true
    },
    publicField: {
      type: Boolean,
      default: false,
      required: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    status: { type: String, required: false },
    label: { type: String, required: false },
    placeholder: { type: String, required: false }
  },
  data: () => ({
    products: [],
    search: "",
    selectedProduct: null,
    loading: false
  }),
  methods: {
    onInput(obj) {
      this.search = "";
      this.$emit("input", obj);
    },
    fetchData() {
      this.loading = true;
      let filters = {};
      filters.keyword = this.search;

      let url = "/api/products/search";

      if (this.publicField) {
        filters.programGroupKey = this.programGroupKey;
        url = url + "/public";
      } else {
        filters.isEffective = this.status;
      }

      ApiService.post(url, filters)
        .then(({ data }) => {
          this.$set(this, "products", data.content);
          this.loading = false;
        })
        .catch(error => {
          console.error("Error fetching products: ", error);
          this.loading = false;
        });
    },
    validate() {
      const selectedProducts = Array.isArray(this.selectedProduct)
        ? this.selectedProduct
        : this.selectedProduct
        ? [this.selectedProduct]
        : [];

      if (this.minimumCount >= 1) {
        if (
          selectedProducts?.length >= this.minimumCount &&
          (this.maximumCount === undefined || selectedProducts?.length <= this.maximumCount)
        ) {
          return true;
        } else {
          return `Select at least ${this.minimumCount} product${this.minimumCount > 1 ? "s" : ""}`;
        }
      } else {
        return selectedProducts?.length > 0 ? true : "At least one product is needed";
      }
    },
    displayItem(item) {
      return `${item.productKey} - ${item.name}`;
    }
  },
  watch: {
    search() {
      if (this.search && this.search.trim()?.length >= 3) {
        this.fetchData();
      }
    },
    value: {
      immediate: true,
      handler(v) {
        if (v) {
          if (!this.products || this.products?.length == 0) {
            this.products = [v];
          }
          this.selectedProduct = v;
        }
      }
    }
  },
  computed: {
    ...mapGetters(["selectedProgram"])
  }
};
</script>
